import * as React from 'react';
import {
    appSettingsActions,
    authSelector,
    combineStrings,
    ContextVersionUtils,
    docsSelector,
    Empty,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    IDocumentStatus,
    IQueryParam,
    IReaderTool,
    IWorkflowActivity,
    IWorkflowHistoricActivity,
    modulesSelector,
    ModuleVersionHeader,
    ModuleVersionUtils,
    ModuleViewDiffingSwitch,
    OutlineUtils,
    SnackBar,
    Spinner,
    SplitView,
    useHistory,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { InsertDriveFileOutlined as Document, ViewCarousel } from '@material-ui/icons';
import { Fab, IconButton } from '@material-ui/core';
import { CRName, WorkflowCRProposalScreen } from '../../../config';
import { DraftView, ModuleVersionView, TextProposalView } from './SplitViews';
import { useWorkflow } from '../../../context';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IWebApplicationStore } from '../../../interfaces';
import { useSelectorUiWeb } from '../../../store';
import { useRouteMatch } from 'react-router';

interface IProps {
    document: IApiContextVersion;
    changeRequest: IWorkflowActivity | IWorkflowHistoricActivity;
    crModuleVersion: IApiModuleVersion | null;
    previousModuleVersion: IApiModuleVersion | null;
    setIsEditing: (isEditing: boolean) => void;
}

export const ChangeRequestWorkflowSplitView: React.FC<IProps> = ({
    document,
    changeRequest,
    crModuleVersion,
    previousModuleVersion,
    setIsEditing,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { pushUrl } = useHistory();
    const { getCREdit } = useWorkflow('cr');

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersions = useSelectorUiWeb((state) => docsSelector.getVersions(state, documentOid));
    const hasDraftDocsAccess = useSelector(authSelector.hasDraftDocsAccess);

    const [page, setPage] = React.useState(0);
    const [editActive, setEditActive] = React.useState(false);
    const [popoverActive, setPopoverActive] = React.useState(false);
    const [timedOut, setTimedOut] = React.useState<boolean>(false);

    React.useEffect(() => {
        dispatch(appSettingsActions.diffingSettingsRequested());
    }, []);

    const isModuleDiffingActive = useSelector((state: IWebApplicationStore) => state.appSettings.moduleDiffing);
    const previousVersion = useSelector((state: IApplicationStore) =>
        modulesSelector.previousVersion(state, crModuleVersion?.moduleOid, crModuleVersion?.oid)
    );

    const isDeleteCR =
        changeRequest.activityName == CRName.DELETE_MODULE ||
        changeRequest.processDefinitionName == CRName.DELETE_MODULE;
    const isContentCR =
        changeRequest.activityName == CRName.EDIT_CONTENT || changeRequest.processDefinitionName == CRName.EDIT_CONTENT;
    const useEditor = changeRequest.variables.PROPOSAL_SCREEN === WorkflowCRProposalScreen.EDITOR;

    const contextVersionToEdit = contextVersions?.find(
        (v) => v.oid === WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
    );

    const getPreviousContextVersion = () => {
        if (contextVersions?.length > 1 && contextVersionToEdit) {
            // We are getting only released ones because we can have temporary revision and regular revision open at the same time
            const versionsSorted = ContextVersionUtils.sortByLatestEffective(contextVersions).filter(
                (v) => v.status === IDocumentStatus.RELEASED
            );
            const indexOfContextVersionToEdit = versionsSorted.indexOf(contextVersionToEdit);
            if (versionsSorted.length === indexOfContextVersionToEdit + 1) {
                return undefined;
            }
            return versionsSorted[indexOfContextVersionToEdit + 1];
        }
        return undefined;
    };

    let previousContextVersion = getPreviousContextVersion();
    // If there is no previous context version use contextVersionToEdit
    if (!previousContextVersion) {
        previousContextVersion = contextVersionToEdit;
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setTimedOut(true);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    if (crModuleVersion === null) {
        return (
            <>
                <Spinner />
                {timedOut && (
                    <SnackBar
                        key={0}
                        isOpen={true}
                        message={t('workflow.revision.errors.notPublished')}
                        variant="warning"
                        position="fixed"
                    />
                )}
            </>
        );
    }

    const crEdit = getCREdit(changeRequest.processInstanceId);

    const getModuleVersionTitle = () => {
        if (crEdit && crEdit.title && crEdit.title.moduleVersionTitle) {
            return crEdit.title.moduleVersionTitle;
        }

        if (changeRequest.variables.NEW_MODULE_VERSION_TITLE) {
            return changeRequest.variables.NEW_MODULE_VERSION_TITLE;
        }

        if (!contextVersionToEdit) {
            return t('reader.moduleVersion.notFound');
        }

        return contextVersionToEdit.moduleVersionOutlineInfo[crModuleVersion.moduleOid]?.moduleVersionTitle;
    };

    const moduleVersionNumbering =
        contextVersionToEdit && ModuleVersionUtils.getModuleVersionNumbering(contextVersionToEdit, crModuleVersion);
    const moduleVersionTitle = getModuleVersionTitle();

    // If change request is released and is content change request previous module version is not correct so we need to fix it
    const fixedPreviousModuleVersion =
        WorkflowUtils.isReleased(changeRequest, contextVersions) && isContentCR
            ? previousVersion
            : previousModuleVersion;

    const previousModuleVersionNumbering =
        fixedPreviousModuleVersion &&
        previousContextVersion &&
        ModuleVersionUtils.getModuleVersionNumbering(previousContextVersion, fixedPreviousModuleVersion);

    const previousModuleVersionTitle =
        fixedPreviousModuleVersion && previousContextVersion
            ? previousContextVersion.moduleVersionOutlineInfo[fixedPreviousModuleVersion.moduleOid]
                  ?.moduleVersionTitle ?? t('reader.moduleVersion.notFound')
            : t('reader.moduleVersion.notFound');

    const openDoc = () => {
        pushUrl(`/doc/${document.oid}/${getModulePath()}`, [
            {
                key: IQueryParam.READER_TOOLS,
                value: IReaderTool.MODULE_VIEW,
            },
        ]);
    };

    const getModulePath = () => {
        return OutlineUtils.findModuleInOutline(document.outline, crModuleVersion.moduleOid).path.join('/');
    };

    const setSplitViewDisabled = (value: boolean) => {
        setEditActive(value);
    };

    const toggleDiffing = () => {
        dispatch(appSettingsActions.saveDiffingSettingsRequested(!isModuleDiffingActive));
    };

    const proposedCrModuleVersionView = {
        header: (
            <ModuleVersionHeader
                text={combineStrings([moduleVersionNumbering, moduleVersionTitle])}
                diffingAction={<ModuleViewDiffingSwitch action={toggleDiffing} isActive={isModuleDiffingActive} />}
            />
        ),
        content: (
            <TextProposalView
                document={previousContextVersion}
                changeRequest={changeRequest}
                crModuleVersion={crModuleVersion}
                previousModuleVersion={fixedPreviousModuleVersion}
                setIsEditing={setIsEditing}
                setSplitViewDisabled={setSplitViewDisabled}
                currentPage={page}
                diffingActive={isModuleDiffingActive}
            />
        ),
    };

    const previousHighlightedModuleVersionView = {
        header: (
            <ModuleVersionHeader
                text={combineStrings([previousModuleVersionNumbering, previousModuleVersionTitle])}
                action={<IconButton className="open-doc" children={<Document />} onClick={openDoc} />}
            />
        ),
        content: (
            <ModuleVersionView
                document={previousContextVersion}
                changeRequest={changeRequest}
                moduleVersion={
                    previousModuleVersionTitle === t('reader.moduleVersion.notFound')
                        ? null
                        : fixedPreviousModuleVersion
                }
            />
        ),
    };

    const draftView = {
        header: (
            <ModuleVersionHeader
                text={
                    isDeleteCR
                        ? t('reader.moduleVersion.deleted')
                        : combineStrings([moduleVersionNumbering, moduleVersionTitle])
                }
                diffingAction={
                    page % 3 !== 1 ? (
                        <ModuleViewDiffingSwitch action={toggleDiffing} isActive={isModuleDiffingActive} />
                    ) : null
                }
            />
        ),
        content: (
            <DraftView
                document={contextVersionToEdit}
                changeRequest={changeRequest}
                crModuleVersion={
                    moduleVersionTitle === t('reader.moduleVersion.notFound') || isDeleteCR ? null : crModuleVersion
                }
                setIsEditing={setIsEditing}
                setSplitViewDisabled={setSplitViewDisabled}
                currentPage={page}
                diffingActive={isModuleDiffingActive}
            />
        ),
    };

    const getViews = () => {
        if (!useEditor) {
            return [previousHighlightedModuleVersionView, draftView];
        }

        if (page % 3 === 0) {
            return [previousHighlightedModuleVersionView, proposedCrModuleVersionView];
        } else if (page % 3 === 1) {
            return [proposedCrModuleVersionView, draftView];
        } else {
            return [draftView, previousHighlightedModuleVersionView];
        }
    };

    const swithToView = (active: boolean, pageNumber: number) => {
        !active ? setPage(pageNumber) : setPopoverActive(active);
    };

    return !hasDraftDocsAccess ? (
        <div className="change-request__split-view">
            <Empty className="content empty" variant="text" text={t('workflow.changeRequest.errors.noViewRole')} />
        </div>
    ) : contextVersionToEdit ? (
        <div className="change-request__split-view">
            <SplitView className="change-request-comparator" views={getViews()} />
            {useEditor && (
                <React.Fragment>
                    <Fab
                        className={`switch-view ${editActive ? 'disabled' : 'enabled'}`}
                        children={<ViewCarousel />}
                        onClick={() => swithToView(editActive, page + 1)}
                        data-testid="switchViewBtn"
                    />
                    {popoverActive && (
                        <SnackBar
                            isOpen={true}
                            message={t('workflow.changeRequest.errors.splitViewBlocked')}
                            variant="warning"
                            position="fixed"
                            onClose={() => setPopoverActive(false)}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    ) : (
        <Spinner />
    );
};
