import { ApiConstants } from '../../utils/ApiConstants';

export class FormConstants extends ApiConstants {
    static getFormSubmissionType(formSubmissionTypeOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/form-submission-types/${formSubmissionTypeOid}`);
    }

    static getAllFormSubmissionTypes(): URL {
        return ApiConstants.relativeUrl(`/v1/form-submission-types/`);
    }

    static getAllFormSubmissions(): URL {
        return ApiConstants.relativeUrl(`/v1/form-submissions`);
    }

    static getManageableFormSubmissions(): URL {
        return ApiConstants.relativeUrl(`/v1/manager/form-submissions`);
    }

    static getManageableFormSubmission(submissionOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/manager/form-submissions/${submissionOid}`);
    }

    static updateManageableFormSubmissionStatus(submissionOid: string, newStatusOid: string): URL {
        return ApiConstants.relativeUrl(
            `/v1/manager/form-submissions/${submissionOid}/updateStatus?newStatusOid=${newStatusOid}`
        );
    }

    static getFormSubmissions(formVersionOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/form-submissions?formVersionOid=${formVersionOid}`);
    }

    static getFormSubmission(formSubmissionOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/form-submissions/${formSubmissionOid}`);
    }

    static saveFormSubmission(): URL {
        return ApiConstants.relativeUrl('/v1/form-submissions');
    }

    static submitFormSubmission(formSubmissionOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/form-submissions/${formSubmissionOid}/submit`);
    }

    static updateFormSubmission(formSubmissionOid: string): URL {
        return ApiConstants.relativeUrl(`/v1/form-submissions/${formSubmissionOid}`);
    }
}
