import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    ChangeRequestWorkflowTab,
    ChangesProvider,
    changesSelector,
    docsActions,
    docsSelector,
    FullScreenChangeRequestButton,
    GoToDashboardButton,
    Layout,
    routerSelectors,
    Spinner,
    userActions,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { Typography } from '@material-ui/core';
import { crActions, revisionActions, useSelectorUiWeb } from '../../store';
import { ChangeRequestWorkflow } from '../../components';
import { ConnectedWebsocketProvider, ConnectedWorkflowProvider } from '../../context';
import type { RouteComponentProps } from 'react-router-dom';

interface ChangeRequestProps
    extends RouteComponentProps<{
        documentOid: string;
    }> {}

export const ChangeRequest: React.FC<ChangeRequestProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { documentOid } = props.match.params;
    const contextVersion = useSelectorUiWeb((state) => docsSelector.getDocument(state, documentOid));
    const contextVersions = useSelectorUiWeb((state) => docsSelector.getVersions(state, documentOid));
    const { changeRequest: processInstanceId, workflowTab } = useSelector(routerSelectors.queryParams);
    const { username } = useSelectorUiWeb((state) => state.user.userSettings);
    const { isLoading } = useSelectorUiWeb((state) => state.docs);
    const moduleChanges = useSelectorUiWeb((state) => changesSelector.getAllModuleVersionChanges(state));

    const [fullScreenActive, setFullScreenActive] = React.useState(false);

    const { changeRequest, isLoadingCr } = useSelectorUiWeb((state) => {
        return {
            changeRequest: state.workflow.changeRequest.currentCR,
            isLoadingCr: state.workflow.changeRequest.isLoading,
        };
    });

    const activateFullScreen = (value: boolean) => {
        setFullScreenActive(value);
    };

    useEffect(() => {
        dispatch(crActions.calculatedDiffClear());
    }, []);

    useEffect(() => {
        dispatch(docsActions.documentRequested({ documentId: documentOid, filters: [] }));
        if (username === '') {
            dispatch(userActions.userSettingsRequested());
        }
        dispatch(revisionActions.allRevisionsRequested());
        dispatch(crActions.getChangeRequest({ changeRequestId: processInstanceId }));
    }, []);

    useEffect(() => {
        changeRequest &&
            dispatch(
                crActions.contextVersionImportJobHasChangesRequested(
                    WorkflowUtils.getContextVersionToEditOid(changeRequest.variables)
                )
            );
    }, [changeRequest?.processInstanceId]);

    const documentType = contextVersion ? contextVersion.type : 'ovidius-export-type';

    return (
        <ChangesProvider
            value={{
                contextVersion: [],
                moduleVersionsOfContextVersion: [],
                moduleVersions: {},
                moduleVersionChangesRequested: () => ({} as any),
                documentChanges: {},
                moduleChanges,
            }}
        >
            <ConnectedWorkflowProvider>
                <ConnectedWebsocketProvider>
                    <Layout
                        title={t('workflow.changeRequest.title')}
                        variant="workflow"
                        contentCssPaths={[`/api/content-styling/${documentType}`]}
                        navbarProps={{
                            contextVersion: contextVersion,
                            contextVersions: contextVersions,
                            leftActionButtons: [
                                <GoToDashboardButton key={0} />,
                                <p key={1} className="doc-title">
                                    {contextVersion && contextVersion.title}
                                </p>,
                            ],
                            rightActionButtons: [
                                <FullScreenChangeRequestButton
                                    fullScreenActivation={() => activateFullScreen(!fullScreenActive)}
                                    key={1}
                                />,
                            ],
                        }}
                    >
                        {isLoading || isLoadingCr ? (
                            <Spinner className="container" />
                        ) : contextVersion ? (
                            changeRequest ? (
                                <ChangeRequestWorkflow
                                    document={contextVersion}
                                    changeRequest={changeRequest}
                                    workflowTab={workflowTab as ChangeRequestWorkflowTab}
                                    fullScreenActive={fullScreenActive}
                                    contextVersions={contextVersions}
                                />
                            ) : (
                                <Spinner className="container" />
                            )
                        ) : (
                            <Typography className="no-items">{t('reader.noDocument')}</Typography>
                        )}
                    </Layout>
                </ConnectedWebsocketProvider>
            </ConnectedWorkflowProvider>
        </ChangesProvider>
    );
};
