import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
    CompareRevisionsJob,
    CompareRevisionsResult,
    CompareRevisionsStatus,
    YonderButton,
} from '@yonder-mind/ui-core';

interface CompareRevisionsGenerateButtonProps {
    hasCompareRevisionsJobRunning: boolean;
    isCurrentCompareRevisionsProgressing: boolean;
    isActiveCompareRevisionsJobsLoading: boolean;
    areFormFieldsFilled: boolean;
    isLabelValid: boolean;
    currentCompareRevisionsJob: CompareRevisionsJob | undefined;
    compareRevisionsResults: CompareRevisionsResult[];
    startCompareRevisions: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        submit: {
            margin: theme.spacing(3, 1, 2),
        },
        loadingInfo: {
            margin: theme.spacing(0, 1, 2),
        },
        circleSpinner: {
            marginRight: theme.spacing(1),
        },
    })
);

export const CompareRevisionsGenerateButton: React.FC<CompareRevisionsGenerateButtonProps> = ({
    hasCompareRevisionsJobRunning,
    isCurrentCompareRevisionsProgressing,
    isActiveCompareRevisionsJobsLoading,
    areFormFieldsFilled,
    isLabelValid,
    currentCompareRevisionsJob,
    compareRevisionsResults,
    startCompareRevisions,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const getCompareRevisionsResultsName = () => {
        return compareRevisionsResults.find((result) => result.oid === currentCompareRevisionsJob?.resultOid)?.name;
    };

    return (
        <>
            <YonderButton
                className={classes.submit}
                data-testid={'compare-revisions-generate-button'}
                variant="contained"
                color="primary"
                disabled={
                    isActiveCompareRevisionsJobsLoading ||
                    hasCompareRevisionsJobRunning ||
                    isCurrentCompareRevisionsProgressing ||
                    !areFormFieldsFilled ||
                    !isLabelValid
                }
                onClick={() => startCompareRevisions()}
            >
                {(hasCompareRevisionsJobRunning || isCurrentCompareRevisionsProgressing) && (
                    <CircularProgress className={classes.circleSpinner} size={20} />
                )}
                {hasCompareRevisionsJobRunning || isCurrentCompareRevisionsProgressing
                    ? t('revisionDiffer.startButton.loading')
                    : t('revisionDiffer.startButton.idle')}
            </YonderButton>
            {(hasCompareRevisionsJobRunning || isCurrentCompareRevisionsProgressing) && (
                <Typography
                    data-testid={'compare-revisions-loading-message'}
                    className={classes.loadingInfo}
                    component={'div'}
                    variant={'caption'}
                >
                    {t('revisionDiffer.messages.loading')}
                </Typography>
            )}
            {currentCompareRevisionsJob?.status === CompareRevisionsStatus.FINISHED && (
                <Typography
                    data-testid={'compare-revisions-finished-message'}
                    className={classes.loadingInfo}
                    component={'div'}
                    variant={'caption'}
                >
                    {`${t('revisionDiffer.messages.finished')} `}
                    <Link
                        href={`${window.location.origin}/api/compare-revisions/${currentCompareRevisionsJob?.resultOid}/html`}
                        target={'blank'}
                    >
                        {getCompareRevisionsResultsName()}
                    </Link>
                </Typography>
            )}
        </>
    );
};
