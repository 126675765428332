import { ActionType, getType } from 'typesafe-actions';
import { IImportStore } from '../../interfaces/store/import/IImportStore';
import * as actions from './import-job-actions';

export type ImportJobActions = ActionType<typeof actions>;

const initialState: IImportStore = {
    importJobLock: {},
    isLoading: false,
};

export default function importJobReducer(state: IImportStore = initialState, action: ImportJobActions): IImportStore {
    switch (action.type) {
        case getType(actions.importJobLockRequested):
            return {
                ...state,
                importJobLock: {},
                isLoading: true,
            };

        case getType(actions.importJobLockReceived): {
            const contextVersionOid = action.payload.contextVersionOid;
            return {
                ...state,
                importJobLock: {
                    ...state.importJobLock,
                    [contextVersionOid]: {
                        ...state.importJobLock[contextVersionOid],
                        data: action.payload.importJobLockData,
                    },
                },
                isLoading: false,
            };
        }

        case getType(actions.importJobLockError): {
            const contextVersion = action.payload.contextVersionOid;
            return {
                ...state,
                importJobLock: {
                    ...state.importJobLock,
                    [contextVersion]: {
                        ...state.importJobLock[contextVersion],
                        error: action.payload.error,
                    },
                },
            };
        }

        case getType(actions.importJobLockReset):
            return {
                importJobLock: {},
                isLoading: false,
            };
        default:
            return state;
    }
}
