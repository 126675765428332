import { Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ContextVersionUtils,
    docsActions,
    docsSelector,
    IApplicationStore,
    IWorkflowFormField,
    IWorkflowType,
    modulesSelector,
    ModuleViewTool,
    OutlineUtils,
    routerSelectors,
    SortUtils,
    Spinner,
    StatusBar,
    YonderList,
} from '@yonder-mind/ui-core';
import { useWorkflow } from '../../../../context';
import { IFormValues } from '../../../../interfaces';
import { ChangeRequestForm } from './ChangeRequestForm';
import { ChangeRequestButton } from './ChangeRequestButton';
import { useDispatch, useSelector } from 'react-redux';
import { crActions, useSelectorUiWeb } from '../../../../store';
import { ChangeRequestV2ListItem } from '../../../List/ListItemVariants/ChangeRequestV2ListItem';
import { ChangeRequestUtils } from '../../../../utils';
import { useRouteMatch } from 'react-router';

interface ChangeRequestProps {
    setChangeRequestCount: (count: number) => void;
}

export const ChangeRequest: React.FC<ChangeRequestProps> = ({ setChangeRequestCount }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const crWorkflow = useWorkflow('cr');

    const { params: routerParams } = useRouteMatch<{ documentOid: string }>();
    const { documentOid } = routerParams;
    const contextVersion = useSelector((state: IApplicationStore) => docsSelector.getDocument(state, documentOid));
    const contextVersions = useSelector((state: IApplicationStore) => docsSelector.getVersions(state, documentOid));

    const { moduleVersion } = useSelector((state: IApplicationStore) => state.moduleView);
    const moduleOid = moduleVersion ? moduleVersion.moduleOid : '';
    const module = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextOid(state, moduleOid, contextVersion?.oid)
    );

    const version = contextVersions?.find((v) => v.oid === contextVersion.oid);
    const isHomeDocument = module ? version.contextOid === module.masterContextOid : true;
    const isDraft = ContextVersionUtils.isDraft(contextVersion);

    React.useEffect(() => {
        if (crWorkflow.startForm === null || crWorkflow.startForm === undefined) {
            Object.keys(IWorkflowType).forEach((type) =>
                crWorkflow.actions.requestNewCRForm(contextVersion.type, contextVersion.subType, IWorkflowType[type])
            );
        }
    }, []);

    const { changeRequestsForModule, isLoading } = useSelectorUiWeb((state) => {
        return {
            changeRequestsForModule: [
                ...state.workflow.changeRequest.moduleCRs.sort((cr1, cr2) =>
                    SortUtils.changeRequestLabelComparator(cr1, cr2)
                ),
                ...state.workflow.changeRequest.activeCRs.filter(
                    (cr) => cr.variables.OLD_MODULE_VERSION_ID === moduleVersion.oid
                ),
                ...state.workflow.changeRequest.finishedCRs.filter(
                    (cr) => cr.variables.OLD_MODULE_VERSION_ID === moduleVersion.oid
                ),
            ],
            isLoading: state.workflow.changeRequest.isLoadingModuleCRs,
        };
    });

    React.useEffect(() => {
        if (!isLoading) {
            setChangeRequestCount(changeRequestsForModule.length);
        }
    }, [changeRequestsForModule]);

    const openedModulesPath = useSelectorUiWeb((state) => {
        const moduleOidPathParams = routerSelectors.pathParams(state).moduleOidPathParams;
        const moduleId =
            moduleOidPathParams !== undefined && moduleOidPathParams.length > 0
                ? moduleOidPathParams.at(-1)
                : routerSelectors.queryParams(state).moduleOid;
        return OutlineUtils.flatten(version?.outline).find((mod) => mod.moduleOid == moduleId)?.path;
    });

    const moduleVersionIdToCRs = useSelectorUiWeb((state) => {
        const allCRs = [
            ...state.workflow.changeRequest.moduleCRs,
            ...state.workflow.changeRequest.activeCRs,
            ...state.workflow.changeRequest.finishedCRs,
        ];

        return allCRs.reduce((group, cr) => {
            const moduleVersionId = cr.variables.CHANGE_REQUEST_MODULE_VERSION_ID;
            group[moduleVersionId] = group[moduleVersionId] ?? [];
            group[moduleVersionId].push(cr);
            return group;
        }, {});
    });

    const shouldDisableAddCRbutton = useMemo(() => {
        const openedPath = ChangeRequestUtils.openedModuleVersionsPath(openedModulesPath, version);
        return openedPath.some((moduleVersionId) =>
            ChangeRequestUtils.hasDeleteModule(moduleVersionId, moduleVersionIdToCRs)
        );
    }, [changeRequestsForModule, openedModulesPath]);

    const dispatchGetChangeRequestsForModule = () =>
        dispatch(
            crActions.getChangeRequestsForModule({
                contextOid: contextVersion.contextOid,
                contextVersionOid: contextVersion.oid,
                isDraft,
                moduleOid: module.oid,
            })
        );

    const dispatchGetSidebarCrs = () => {
        dispatch(
            crActions.getChangeRequestsForWfSidebar({
                contextOid: contextVersion.contextOid,
                contextVersionOid: contextVersion.oid,
                isDraft: ContextVersionUtils.isDraft(contextVersion),
            })
        );
    };

    const requestDocumentVersions = () => {
        dispatch(docsActions.documentVersionsRequested(contextVersion.contextOid));
    };

    useEffect(() => {
        if (module && contextVersion) {
            dispatchGetChangeRequestsForModule();
        }
    }, [contextVersion?.oid, module?.oid]);

    const [newCRType, setNewCRType] = React.useState<IWorkflowType>(IWorkflowType.EDIT_CONTENT);
    const [newCRFormFields, setNewCRFormFields] = React.useState<IWorkflowFormField[] | undefined>(undefined);
    const closeChangeRequestForm = () => setNewCRFormFields(undefined);

    const handleSubmitNewChangeRequest = (values: IFormValues) => {
        closeChangeRequestForm();
        dispatch(
            crActions.addChangeRequest({
                contextType: contextVersion.type,
                workflowId: newCRType,
                contextOid: contextVersion.contextOid,
                contextVersionOid: contextVersion.oid,
                previousContent: moduleVersion.content,
                data: values,
                moduleOid: module.oid,
                isDraft,
            })
        );
    };

    const renderAddRevisionButton = () =>
        crWorkflow.startForm && ContextVersionUtils.isValid(version) && isHomeDocument ? (
            <ChangeRequestButton
                changeRequests={changeRequestsForModule}
                setNewCRType={setNewCRType}
                setNewCRFormFields={setNewCRFormFields}
                shouldDisableButton={shouldDisableAddCRbutton}
                refetchOnOpen={() => {
                    dispatchGetChangeRequestsForModule();
                    requestDocumentVersions();
                    dispatchGetSidebarCrs();
                }}
            />
        ) : null;

    const renderStatusBar = () => {
        return crWorkflow.startForm === undefined ? (
            <StatusBar
                text={t('reader.moduleView.tools.changeRequest.statusBar.noRoleAssigned')}
                color={ContextVersionUtils.isInvalid(version) ? 'orange' : 'blue'}
            />
        ) : crWorkflow.startForm && (ContextVersionUtils.isInvalid(version) || ContextVersionUtils.isDraft(version)) ? (
            <StatusBar
                text={t('reader.moduleView.tools.changeRequest.statusBar.invalidDocVersion')}
                color={ContextVersionUtils.isInvalid(version) ? 'orange' : 'blue'}
            />
        ) : crWorkflow.startForm && ContextVersionUtils.isValid(version) && !isHomeDocument ? (
            <StatusBar text={t('reader.moduleView.tools.changeRequest.statusBar.notHomeDocument')} color={'blue'} />
        ) : null;
    };

    const renderChangeRequestForm = () =>
        moduleVersion && newCRFormFields ? (
            <ChangeRequestForm
                type={newCRType}
                fields={newCRFormFields}
                moduleVersion={moduleVersion}
                onClose={closeChangeRequestForm}
                onSubmit={handleSubmitNewChangeRequest}
            />
        ) : null;

    return (
        <ModuleViewTool className="change-request-tool">
            {isLoading ? (
                <Spinner />
            ) : (
                <YonderList
                    dataTestId="moduleViewChangeRequestsList"
                    variant="custom"
                    componentListItem={ChangeRequestV2ListItem}
                    items={changeRequestsForModule}
                    emptyComponent={
                        <Typography className="no-items">
                            {t('reader.moduleView.tools.changeRequest.noItems')}
                        </Typography>
                    }
                />
            )}
            {renderAddRevisionButton()}
            {renderStatusBar()}
            {renderChangeRequestForm()}
        </ModuleViewTool>
    );
};
