import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
    authSelector,
    combineStrings,
    docsActions,
    fileDropActions,
    filterAndSortActions,
    flashCardsActions,
    folderActions,
    IApiTaskType,
    Layout,
    tasksActions,
    tenantSettingsActions,
    userActions,
    userSettingsActions,
} from '@yonder-mind/ui-core';
import { revisionActions } from '../../store';
import {
    DocumentsCard,
    DragAndDropFavoritesCard,
    FlashcardsCard,
    NotificationsCard,
    WorkflowsCard,
} from '../../components';

const WORKFLOW_TASK_TYPES: IApiTaskType[] = [
    IApiTaskType.WF_CONTEXT_REVISION,
    IApiTaskType.WF_CHANGE_REQUEST,
    IApiTaskType.DISCUSSION,
];

export const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const hasWorkflowRole = useSelector(authSelector.hasWorkflowRole);
    const hasFlashcardAccess = useSelector(authSelector.hasFlashcardAccess);
    const hasYonUserRole = useSelector(authSelector.hasYonUserRole);

    useEffect(() => {
        dispatch(folderActions.folderStructureRequested({ processResponse: true }));
        dispatch(filterAndSortActions.filterAndSortDocumentsValuesRequested());
        dispatch(filterAndSortActions.filterAndSortTasksValuesRequested());
        dispatch(filterAndSortActions.filterAndSortDocumentNotificationsValuesRequested());
        dispatch(filterAndSortActions.filterAndSortChangeRequestNotificationsValuesRequested());
        dispatch(docsActions.contextVersionsMetadataRequested());
        dispatch(docsActions.tagDomainsRequested());
        dispatch(userActions.userSettingsRequested());
        dispatch(tenantSettingsActions.tenantSettingsRequested());
        dispatch(revisionActions.allRevisionsRequested());
        dispatch(filterAndSortActions.filterFavoritesValuesRequested());
        dispatch(userSettingsActions.userFavoritesRequested());

        if (hasYonUserRole) {
            dispatch(filterAndSortActions.filterAndSortFilesValuesRequested());
            dispatch(fileDropActions.getFileListDashboard());
        }

        if (hasFlashcardAccess) {
            dispatch(flashCardsActions.flashcardSetsRequested());
        }

        if (hasWorkflowRole) {
            dispatch(
                tasksActions.tasksRequested({
                    taskTypes: WORKFLOW_TASK_TYPES,
                    latestByTitleAndMessage: true,
                })
            );
        }
    }, []);

    return (
        <Layout title={t('dashboard.title')} variant="dashboard">
            <Grid
                className={combineStrings(['dashboard__column', hasWorkflowRole ? 'has-workflow two-items-md' : null])}
                item={true}
                xs={12}
                md={hasWorkflowRole ? 4 : 8}
                lg={hasWorkflowRole ? 6 : 8}
            >
                <Grid className="dashboard__item" item={true} xs lg={hasWorkflowRole ? 6 : 12}>
                    <DocumentsCard />
                </Grid>
                <Grid className="dashboard__item" item={true} xs lg={hasWorkflowRole ? 6 : 12}>
                    <DragAndDropFavoritesCard />
                </Grid>
            </Grid>
            <Grid
                className={combineStrings(['dashboard__column', hasFlashcardAccess ? 'two-items-xs' : null])}
                item={true}
                xs={12}
                md={4}
                lg={hasWorkflowRole ? 3 : 4}
            >
                <Grid className="dashboard__item" item={true}>
                    <NotificationsCard />
                </Grid>
                {hasFlashcardAccess && (
                    <Grid className="dashboard__item" item={true} xs>
                        <FlashcardsCard />
                    </Grid>
                )}
            </Grid>
            {hasWorkflowRole && (
                <Grid className="dashboard__column" item={true} xs={12} md={4} lg={3}>
                    <Grid className="dashboard__item" item={true} xs>
                        <WorkflowsCard />
                    </Grid>
                </Grid>
            )}
        </Layout>
    );
};
