import { createSelector } from 'reselect';
import {
    docsSelector,
    IDocumentStatus,
    IWorkflowActivity,
    IWorkflowFormField,
    IWorkflowHistoricActivity,
    WorkflowUtils,
} from '@yonder-mind/ui-core';
import { IWebApplicationStore } from '../../../interfaces';

const getRevisions = (state: IWebApplicationStore): IWorkflowActivity[] => state.workflow.revision.allRevisions || [];

const getRevisionHistory = (state: IWebApplicationStore, documentId: string): IWorkflowHistoricActivity[] =>
    state.workflow.revision.documentRevisionHistories[documentId] || [];

const getNewRevisionsFormFields = (state: IWebApplicationStore): IWorkflowFormField[] =>
    state.workflow.revision.startingFormFields || [];

const getRevisionsByContextVersion = (
    state: IWebApplicationStore
): { [contextVersionOid: string]: IWorkflowActivity | undefined } =>
    state.workflow.revision.allRevisions.reduce((acc, revision) => {
        if (revision) {
            return {
                ...acc,
                [WorkflowUtils.getContextVersionToEditOid(revision.variables)]: revision,
            };
        }

        return acc;
    }, {});

const getRevisionsByProcessId = (
    state: IWebApplicationStore
): { [processInstanceId: string]: IWorkflowActivity | undefined } =>
    getRevisions(state).reduce(
        (acc, revision) => ({
            ...acc,
            [revision.processInstanceId]: revision,
        }),
        {}
    );

const getDocumentsInRevision = createSelector(
    [getRevisions, docsSelector.getAllDocumentsMetadata],
    (revisions, documents) => {
        return documents.filter((doc) =>
            revisions.find(
                (rev) =>
                    WorkflowUtils.getContextOid(rev.variables) === doc.contextOid &&
                    doc.status === IDocumentStatus.DRAFT
            )
        );
    }
);

export const revisionSelector = {
    getNewRevisionsFormFields,
    getRevisionHistory,
    getRevisions,
    getRevisionsByContextVersion,
    getRevisionsByProcessId,
    getDocumentsInRevision,
};
