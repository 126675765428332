import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import rangy from 'rangy';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-serializer';
import { v4 as uuidV4 } from 'uuid';
import {
    combineStrings,
    IApiContextVersion,
    IApiModuleVersion,
    IApplicationStore,
    LinkUrlUtils,
    modulesSelector,
} from '@yonder-mind/ui-core';
import { ChangeRequestUtils } from '../../utils';
import { IWebApplicationStore } from '../../interfaces';

export interface ICrSelection {
    location: string;
    textContent: string;
    moduleVersionOid: string;
}

interface IProps {
    doc: IApiContextVersion;
    moduleVersion: IApiModuleVersion;
    crSelections: ICrSelection[];
    showBubbleHighlights: boolean;
    autofocus?: boolean;
}

interface IHighlighterRange {
    crSelection: ICrSelection;
    index: number;
    rangyRange: any;
}

const getCleanModuleVersionContent = (doc: IApiContextVersion, moduleVersion: IApiModuleVersion) => {
    return `<div class="${combineStrings(['document-content fr-view', doc.type])}">${
        moduleVersion.content ? moduleVersion.content : ''
    }</div>`;
};

export const ModuleVersionContentHighlighter: React.FunctionComponent<IProps> = ({
    crSelections,
    moduleVersion,
    doc,
    autofocus,
    showBubbleHighlights,
}) => {
    const module = useSelector((state: IApplicationStore) =>
        modulesSelector.moduleByContextOid(state, moduleVersion?.moduleOid, doc?.oid)
    );

    const usedSelections = crSelections.filter((sel) => sel.moduleVersionOid === moduleVersion.oid);

    const { moduleLinks } = useSelector((state: IWebApplicationStore) => {
        return {
            moduleLinks: state.links.modules[moduleVersion?.moduleOid] ?? [],
        };
    });

    const uid = uuidV4();

    const div = useRef<HTMLDivElement>(null);
    const [highlighter] = useState(rangy.createHighlighter());
    highlighter.addClassApplier(rangy.createClassApplier('cr-highlight'));

    const prepareInternalLinks = (node: Element) => {
        if (node) {
            node.querySelectorAll('a.module-link').forEach((link) => {
                if (link) {
                    LinkUrlUtils.prepareInternalLinks(link, module?.links || moduleLinks || []);
                }
            });
        }
    };

    const cleanUpBubbleHighlights = () => {
        const baseElement = div.current;
        if (!baseElement || !baseElement.parentElement) {
            return;
        }

        baseElement.parentNode.querySelectorAll('.highlightBubble').forEach((childBubble) => {
            baseElement.parentNode.removeChild(childBubble);
        });
    };

    React.useEffect(() => {
        return () => {
            cleanUpBubbleHighlights();
        };
    }, []);

    const generateBubbleHighlights = (ranges: IHighlighterRange[]) => {
        const baseElement = div.current;

        const range = rangy.createRange();
        range.selectNode(baseElement);
        const bounds = range.nativeRange.getBoundingClientRect();

        ranges.forEach((currentRange) => {
            const bubble = document.createElement('div');
            bubble.innerHTML = showBubbleHighlights
                ? `<div class="change-request-highlight-bubble">${currentRange.index + 1}</div>`
                : '';
            bubble.className = `highlightBubble`;
            bubble.id = `cr-bubble-${moduleVersion.oid}-${currentRange.index}`;

            const clientRect = currentRange.rangyRange.nativeRange.getBoundingClientRect();
            bubble.style.cssText = `position: relative; top:${clientRect.top - bounds.top}px; left: ${
                clientRect.left - bounds.left
            }px; width: 0px; height: 0px;`;

            if (baseElement && baseElement.parentElement) {
                baseElement.parentNode.insertBefore(bubble, baseElement);
            }
        });
    };

    const generateRanges = (): IHighlighterRange[] => {
        const baseElement = div.current;
        return usedSelections
            .map((value, index) => {
                try {
                    const rangyRange = rangy.deserializeRange(value.location, baseElement);
                    return {
                        crSelection: value,
                        index,
                        rangyRange,
                    };
                } catch (e) {
                    return null;
                }
            })
            .filter((elem) => elem) as IHighlighterRange[];
    };

    const markHighlights = () => {
        cleanUpBubbleHighlights();
        try {
            highlighter.removeAllHighlights();
            // eslint-disable-next-line
        } catch {}

        if (div.current) {
            div.current.innerHTML = getCleanModuleVersionContent(doc, moduleVersion);
            prepareInternalLinks(div.current);
        }

        const ranges = generateRanges();

        generateBubbleHighlights(ranges);

        const rangyRanges = ranges.map((currentRange) => currentRange.rangyRange);

        const allImages: Element[] = [];
        if (div.current) {
            div.current.querySelectorAll('img').forEach((imgTag) => {
                allImages.push(imgTag);
            });
        }

        const highlightableImages = allImages.filter((imgTag) =>
            rangyRanges.some((rangyRange) => rangyRange.containsNode(imgTag))
        );

        highlighter.highlightRanges('cr-highlight', rangyRanges, {
            containerElement: div.current,
            exclusive: false,
        });

        highlightableImages.forEach((imgTag) => {
            imgTag.classList.add('cr-highlight');
        });

        if (autofocus) {
            ChangeRequestUtils.focusChangeRequest('change-request-subscreen', moduleVersion.oid, 0);
        }
    };

    useLayoutEffect(() => {
        markHighlights();
    }, [usedSelections]);

    // Content rendered by markHighlights()
    return <div className="content module-version-content cr-view" id={uid} ref={div} />;
};
